import {
  Chart as ChartJS,
  CategoryScale,
  defaults
} from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import styles from './Details.module.css';

ChartJS.register(CategoryScale);
defaults.animation = false;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const HouseHoldIncomeMap = {
  'Negative_Nil_income_Tot': 'Negative/Nil',
  'HI_1_149_Tot': '$1-$149',
  'HI_150_299_Tot': '$150-$299',
  'HI_300_399_Tot': '$300-$399',
  'HI_400_499_Tot': '$400-$499',
  'HI_500_649_Tot': '$500-$649',
  'HI_650_799_Tot': '$650-$799',
  'HI_800_999_Tot': '$800-$999',
  'HI_1000_1249_Tot': '$1000-$1249',
  'HI_1250_1499_Tot': '$1250-$1499',
  'HI_1500_1749_Tot': '$1500-$1749',
  'HI_1750_1999_Tot': '$1750-$1999',
  'HI_2000_2499_Tot': '$2000-$2499',
  'HI_2500_2999_Tot': '$2500-$2999',
  'HI_3000_3499_Tot': '$3000-$3499',
  'HI_3500_3999_Tot': '$3500-$3999',
  'HI_4000_more_Tot': '$4000+',
}

const AgeMap = {
  'Age_0_4_yr': '0-4',
  'Age_5_14_y': '5-14',
  'Age_15_19_': '15-19',
  'Age_20_24_': '20-24',
  'Age_25_34_': '25-34',
  'Age_35_44_': '35-44',
  'Age_45_54_': '45-54',
  'Age_55_64_': '55-64',
  'Age_65_74_': '65-74',
  'Age_75_84_': '75-84',
  'Age_85ov_P': '85+',
}

export interface DetailsProps {
  area: number,
  features: any[],
  petbarnStores: any[],
  negativeStores: any[],
  neutralStores: any[],
}

export interface DetailsAndSA1Props extends DetailsProps {
  sa1Data?: {
    [key: string]: any
  }
}

export const Details = ({
  area,
  features,
  petbarnStores,
  negativeStores,
  neutralStores,
  sa1Data
}: DetailsAndSA1Props) => {
  const featureSum = (property: string) => {
    return features.reduce((acc, feature) => {
      return acc + feature.properties[property]
    }, 0)
  }

  const featureSa1Sum = (property: string) => {
    if (!sa1Data) {
      return undefined
    }
    return features.reduce((acc, feature) => {
      return acc + parseFloat(sa1Data[feature.properties.id][property])
    }, 0)
  }

  const totalPopulation = featureSum('Tot_P_P')
  const totalPopulationMale = featureSum('Tot_P_M')
  const totalPopulationFemale = featureSum('Tot_P_F')
  const totalPopulationOthers = Math.abs(totalPopulation - totalPopulationMale - totalPopulationFemale)

  const ageData = (Object.keys(AgeMap) as (keyof typeof AgeMap)[]).map((key) => {
    return {
      label: AgeMap[key],
      value: featureSum(key),
    }
  })

  const incomeData = (Object.keys(HouseHoldIncomeMap) as (keyof typeof HouseHoldIncomeMap)[]).map((key) => {
    return {
      label: HouseHoldIncomeMap[key],
      value: featureSa1Sum(key),
    }
  })


  const ageBarOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Age Distribution',
      },
    },
  };

  const hhIncomeBarOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Weekly HH Income',
      },
    },
  };

  console.log(petbarnStores, negativeStores, neutralStores)

  return (
    <div className={styles.details}>
      <div>Area:&nbsp;{area > 10000 ? (<>
          {(area / 1000000).toFixed(2)} km<sup>2</sup>
        </>) : (<>
          {(area).toFixed(2)} m<sup>2</sup>
        </>)
      }</div>
      <br />
      <div><strong>NPS: {(((petbarnStores.length - negativeStores.length) * 100) / (petbarnStores.length + negativeStores.length + neutralStores.length)).toFixed(1)}</strong></div>
      <div>Positive: {petbarnStores.length}</div>
      <div>Negative: {negativeStores.length}</div>
      <div>Neutral: {neutralStores.length}</div><br />
      <div>Total Population: {numberWithCommas(totalPopulation)}</div>
      <div>- Male: {numberWithCommas(totalPopulationMale)}</div>
      <div>- Female: {numberWithCommas(totalPopulationFemale)}</div>
      <div>- Others: {numberWithCommas(totalPopulationOthers)}</div>


      <Bar
        height={300}
        data={{
          labels: ageData.map((data) => data.label),
          datasets: [
            {
              label: 'Age Distribution',
              data: ageData.map((data) => data.value),
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            }
          ]
        }}
        options={ageBarOptions}
      />
      {sa1Data && (
        <>
          <div>Total Households: {numberWithCommas(featureSa1Sum('Tot_Tot'))}</div>
          <div>- Family Households: {numberWithCommas(featureSa1Sum('Tot_Family_households'))}</div>
          <div>- Non-Family Households: {numberWithCommas(featureSa1Sum('Tot_Non_family_households'))}</div>

          <Bar
            height={400}
            data={{
              labels: incomeData.map((data) => data.label),
              datasets: [
                {
                  label: 'HH Weekly Income',
                  data: incomeData.map((data) => data.value),
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
              ]
            }}
            options={hhIncomeBarOptions}
          />
        </>
        
      )}
    </div>
  )
};
