import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {bbox, intersect} from '@turf/turf'
import mapboxgl, { MapboxGeoJSONFeature } from "mapbox-gl";
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode
} from 'mapbox-gl-draw-circle';
import { uniq } from "../utils";


class DrawButtonSelectorControl {
  constructor(private readonly draw: MapboxDraw,
    private readonly config: {
      onUnselectAllFeatures: () => any
    }) {
  }
  onRemove() {
    return
  }

  onAdd() {
    const div = document.createElement("div");
    div.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    div.innerHTML = `
      <button id='btnSelect' class="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point" title="Single Point tool"></button>
      <button id='btnPolygon' class="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon" title="Polygon tool"></button>
      <button id='btnCircle' class="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_circle" title="Circle tool"></button>
    `;

    div.addEventListener("contextmenu", (e) => e.preventDefault());
    div.querySelector('#btnSelect')?.addEventListener("click", () => {
      console.log('Select')
      this.draw.changeMode('simple_select')
    });
    div.querySelector('#btnPolygon')?.addEventListener("click", () => {
      console.log('Polygon')
      this.draw.changeMode('draw_polygon')
    });
    div.querySelector('#btnCircle')?.addEventListener("click", () => {
      console.log('Circle')
      this.draw.changeMode('draw_circle')
    });
    return div;
  }
}


class DeleteAllSelectorControl {
  constructor(private readonly draw: MapboxDraw,
    private readonly config: {
      onUnselectAllFeatures: () => any
    }) {
  }
  onRemove() {
    return
  }

  onAdd() {
    const div = document.createElement("div");
    div.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    div.innerHTML = `
      <button id='btnDelete' class="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_clear" title="Clear"></button>
    `;

    div.addEventListener("contextmenu", (e) => e.preventDefault());
    div.querySelector('#btnDelete')?.addEventListener("click", () => {
      console.log('Delete')
      this.draw.deleteAll()
      if (this.config.onUnselectAllFeatures) {
        this.config.onUnselectAllFeatures()
      }
    });

    return div;
  }
}

export class ToolDraw {
  protected SASourceLayerName: string
  protected mapDraw: MapboxDraw
  protected drawButtonSelector: DrawButtonSelectorControl
  protected deleteAllSelector: DeleteAllSelectorControl
  protected selectedFeatures: mapboxgl.MapboxGeoJSONFeature[] = []
  protected HoveredFeature: mapboxgl.MapboxGeoJSONFeature | undefined

  constructor(
    private readonly map: mapboxgl.Map, 
    private readonly layerOfInterest: string = 'SA1',
    private readonly config: {
      onSelectedFeaturesChange?: (e: {
        features: mapboxgl.MapboxGeoJSONFeature[],
        unionedRegion: any[]
      }) => any
    }
  ) {
    this.SASourceLayerName = (map.getLayer(layerOfInterest) as any).sourceLayer
    this.mapDraw = new MapboxDraw({
      defaultMode: 'simple_select',
      userProperties: true,
      controls: {
        point: false,
        line_string: false,
        polygon: false,
        trash: true,
        combine_features: false,
        uncombine_features: false
      },
      modes: {
        ...MapboxDraw.modes,
        draw_circle  : CircleMode,
        drag_circle  : DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode
      }
    });
    this.drawButtonSelector = new DrawButtonSelectorControl(this.mapDraw, {
      onUnselectAllFeatures: this.unselectAllFeatures
    });
    this.deleteAllSelector = new DeleteAllSelectorControl(this.mapDraw, {
      onUnselectAllFeatures: this.unselectAllFeatures
    });
    (window as any).mapDrawInstance = this.mapDraw
  }

  selectFeatures = (features: mapboxgl.MapboxGeoJSONFeature[]) => {
    features.forEach((feature) => {
      this.map.setFeatureState(
        {sourceLayer: this.SASourceLayerName, source: 'composite', id: feature.id},
        { active: true }
      )
    })

    this.selectedFeatures = uniq(features, 'id') as MapboxGeoJSONFeature[]
    if (this.config.onSelectedFeaturesChange) {
      
      const unionedRegion = this.selectedFeatures.length > 1 ? this.mapDraw.getAll().features : [this.selectedFeatures[0]];

      this.config.onSelectedFeaturesChange({
        features: this.selectedFeatures,
        unionedRegion: unionedRegion
      })
    }
  }

  unselectAllFeatures = () => {
    this.selectedFeatures.forEach((feature) => {
      this.map.setFeatureState(
        {sourceLayer: this.SASourceLayerName, source: 'composite', id: feature.id},
        { active: false }
      )
    })
    this.selectedFeatures = []
    if (this.config.onSelectedFeaturesChange) {
      this.config.onSelectedFeaturesChange({
        features: [],
        unionedRegion: []
      })
    }
  }

  hoverCurrentFeature = (feature: mapboxgl.MapboxGeoJSONFeature) => {
    this.HoveredFeature = feature
    this.map.setFeatureState(
      {sourceLayer: this.SASourceLayerName, source: 'composite', id: this.HoveredFeature.id},
      { hover: true }
    )
  }
  unhoverCurrentFeature = () => {
    if (this.HoveredFeature) {
      this.map.setFeatureState(
        {sourceLayer: this.SASourceLayerName, source: 'composite', id: this.HoveredFeature.id},
        { hover: false }
      )
    }
    this.HoveredFeature = undefined
  }

  mapOnClick = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    if (!(this.mapDraw.getMode() === 'simple_select' || this.mapDraw.getMode() === 'Select')) {
      return
    }
    
    const selectedFeatures = this.map.queryRenderedFeatures(e.point, {
      layers: ['SA1']
    })
    if (!selectedFeatures[0]) {
      return
    }

    if (this.selectedFeatures.length <= 1) {
      if (this.selectedFeatures[0]?.id === selectedFeatures[0].id) {
        return 
      }
      this.unselectAllFeatures()
      this.selectFeatures([selectedFeatures[0]])
    } else {
      const newSelectedFeatures = this.selectedFeatures.filter((feature) => {
        return feature.id !== selectedFeatures[0].id
      })
      if (newSelectedFeatures.length === this.selectedFeatures.length) {
        this.selectFeatures([...this.selectedFeatures, selectedFeatures[0]])
      } else {
        this.selectFeatures(newSelectedFeatures)
      }
    }
  }

  mapOnMouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const selectedFeatures = this.map.queryRenderedFeatures(e.point, {
      layers: ['SA1']
    })
    if (!selectedFeatures[0]) {
      return
    }
    if (this.HoveredFeature && this.HoveredFeature.id === selectedFeatures[0].id) {
      return 
    }
    this.unhoverCurrentFeature()
    this.hoverCurrentFeature(selectedFeatures[0])
  }

  mapOnMouseLeave = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    return
  }

  updateSelection = () => {
    this.unselectAllFeatures()

    const drawFeatures = this.mapDraw.getAll().features

    if (drawFeatures.length === 0) {
      return
    }

    const selectedFeatures: mapboxgl.MapboxGeoJSONFeature[] = []
    drawFeatures.forEach((drawFeature) => {

      // generate bounding box from polygon the user drew
      const polygonBoundingBox = bbox(drawFeature);
    
      const southWest: [number, number] = [polygonBoundingBox[0], polygonBoundingBox[1]];
      const northEast: [number, number] = [polygonBoundingBox[2], polygonBoundingBox[3]];

      const northEastPointPixel = this.map.project(northEast);
      const southWestPointPixel = this.map.project(southWest);

      const features = this.map.queryRenderedFeatures([southWestPointPixel, northEastPointPixel], {
        layers: [this.layerOfInterest]
      });

      features.forEach((feature) => {
        if (intersect(feature, drawFeature)) {
          selectedFeatures.push(feature)
        }
      })
    })

    console.log('Selected features', this.selectedFeatures)
    this.selectFeatures(selectedFeatures)
  }

  mapOnDrawCreate = (e: any) => {
    console.log('Draw create', e)
    this.updateSelection()
  }

  mapOnDrawUpdate = (e: any) => {
    console.log('Draw update', e)
    this.updateSelection()
  }

  mapOnDrawDelete = (e: any) => {
    console.log('Draw delete', e)
    this.updateSelection()
  }

  register() {
    this.map.addControl(this.deleteAllSelector, 'bottom-left');
    this.map.addControl(this.mapDraw, 'bottom-left');
    this.map.addControl(this.drawButtonSelector, 'bottom-left');
    this.map.on('draw.create', this.mapOnDrawCreate);
    this.map.on('draw.update', this.mapOnDrawUpdate);
    this.map.on('draw.delete', this.mapOnDrawDelete)
    this.map.on('click', this.layerOfInterest, this.mapOnClick);
    this.map.on('mousemove', this.layerOfInterest, this.mapOnMouseMove);
    this.map.on('mouseleave', this.layerOfInterest, this.mapOnMouseLeave);
  }

  unregister() {
    this.map.removeControl(this.deleteAllSelector);
    this.map.removeControl(this.drawButtonSelector);
    this.map.removeControl(this.mapDraw);
    this.map.off('click', this.layerOfInterest, this.mapOnClick);
    this.map.off('mousemove', this.layerOfInterest, this.mapOnMouseMove);
    this.map.off('mouseleave', this.layerOfInterest, this.mapOnMouseLeave);
    this.map.off('draw.create', this.mapOnDrawCreate);
    this.map.off('draw.update', this.mapOnDrawUpdate);
    this.map.off('draw.delete', this.mapOnDrawDelete)
  }
}
