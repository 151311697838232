import { useEffect, useRef, useState } from 'react';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import styles from './Map.module.css';
import { ToolDraw } from './ToolDraw';
import { inside, area } from '@turf/turf';
import { useEffectOnce } from '../utils';
import { Details, DetailsProps } from './Details';
import papaparse from 'papaparse'

mapboxgl.accessToken =
  'pk.eyJ1IjoidGVjaC1oY28iLCJhIjoiY2t6eGVybDd2MDB0NzJucXFsdGhkZWZtcyJ9.5MN03MpCR-NWmr-Oozfttw';
const MAP_STYLE = 'mapbox://styles/tech-hco/cljercidi001j01q48clw2inb';
const SA1_ADDITIONAL_DATA = 'geo/G33_AUST_GDA2021_SA1.csv'

export const Map = () => {
  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng] = useState(137.5);
  const [lat] = useState(-26.21);
  const [zoom] = useState(5.02);
  const [selection, setSelection] = useState<DetailsProps | undefined>(undefined)
  const [sa1Data, setSa1Data] = useState<{
    [key: string]: any
  } | undefined>(undefined)

  useEffectOnce(() => {
    const parseData = async () => {
      const results = await papaparse.parse(window.location.href + SA1_ADDITIONAL_DATA, {
        download: true,
        worker: true,
        header: true,
        complete: (results) => {
          console.log("Finished:", results.data);
          const sa1map: {
            [key: string]: any
          } = {}
          results.data.forEach((d: any) => {
            sa1map[d.id] = d
          })
          setSa1Data(sa1map)
        }
      })
      console.log(results)
    }
    parseData()
  })

  useEffect(() => {
    if (!mapContainer.current) return;
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: MAP_STYLE,
      center: [lng, lat],
      zoom: zoom,
      minZoom: 3,
      attributionControl: false,
    });

    //hide logo
    const mapboxLogo = document.querySelector('.mapboxgl-ctrl-logo') as HTMLElement;;
    if (mapboxLogo) {
      mapboxLogo.style.display = 'none';
    }
    //
    const mapc = map.current
    if (typeof window !== 'undefined') {
      (window as any).mapInstance = mapc;
    }

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      countries: 'AU',
      zoom: 11,
    });
    // Add the control to the map.
    mapc.addControl(geocoder);
    geocoder.on('result', (e: any) => {
      return
    });
    // Add zoom and rotation controls to the map.
    mapc.addControl(new mapboxgl.NavigationControl(),'bottom-left');

    
    const addCluster = (source: string, layer: string, color: string, textColor: string = '#000000') => {
      if (!mapc) return

      mapc.addSource(layer, {
        type: 'geojson',
        data: source,
        cluster: true,
        clusterMaxZoom: 18,
        clusterRadius: 50
      });

      mapc.addSource(`${layer}-raw`, {
        type: 'geojson',
        data: source,
      });

      mapc.addLayer({
        id: `${layer}-raw-point`,
        type: 'circle',
        source: `${layer}-raw`,
        layout: {
          // visibility: 'none'
        },
        paint: {
          'circle-color': '#ffffff',
          'circle-opacity': 0,
        }
      });
      


      mapc.addLayer({
        id: `${layer}-unclustered-point`,
        type: 'circle',
        source: layer,
        // filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': color,
          'circle-radius': 10,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      
      mapc.addLayer({
        id: `${layer}-clusters`,
        type: 'circle',
        source: layer,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': color,
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20, 10, // if 10 stores, radius is 20
            30, 100, // if 100 stores, radius is 30
            40, 1000,
            50 // otherwise 40
          ]
        }
      });

      mapc.addLayer({
        id: `${layer}-cluster-count`,
        type: 'symbol',
        source: layer,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          "text-color": textColor
        }
      });

      mapc.on('mouseenter', `${layer}-clusters`, () => {
        mapc.getCanvas().style.cursor = 'pointer';
      });
      mapc.on('mouseleave', `${layer}-clusters`, () => {
        mapc.getCanvas().style.cursor = '';
      });

      //add a popup
      
      let popup = null;

      mapc.on('click', `${layer}-unclustered-point`, (e) => {
        const features = mapc.queryRenderedFeatures(e.point, {
          layers: [`${layer}-unclustered-point`]
        });

        if (features.length === 0) return;

        features.forEach((feature) => {
          const storeName = feature.properties.name;
          const storeAddress = feature.properties.addressLine;

          // Check if either storeName or storeAddress is undefined
          if (!storeName || !storeAddress) return;

          // Close any existing popups
          if (popup) {
            popup.remove();
            popup = null;
          }

          popup = new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(`<div>
                        <h3>${storeName}</h3>
                        <p>${storeAddress}</p>
                        <button id="close-popup">Close</button>
                    </div>`)
            .addTo(mapc);

          // Close the popup when the close button is clicked
          popup.getElement().querySelector('#close-popup').addEventListener('click', () => {
            popup.remove();
            popup = null;
          });
        });

        e.originalEvent.stopPropagation();
      });


      mapc.on('click', `${layer}-clusters`, (e) => {
        const features = mapc.queryRenderedFeatures(e.point, {
          layers: [`${layer}-clusters`]
        });
        if (!features[0]?.properties) return
        const clusterId = features[0].properties.cluster_id;
        (mapc.getSource(layer) as GeoJSONSource).getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            mapc.easeTo({
              center: (features[0].geometry as any).coordinates,
              zoom: zoom
            });
          }
        );
      });
    }

    mapc.on('load', () => {
      // addCluster('geo/bunnings_features.geojson','bunnings','#0d5257','#ffffff'); 
      // addCluster('geo/petstock_features.geojson', 'petstock', '#032d58', '#ffffff');
      addCluster('geo/review_positive.geojson', 'petbarn-positive', '#5DBB63', '#000000');
      addCluster('geo/review_negative.geojson', 'petbarn-negative', '#ee6b6e', '#000000');
      addCluster('geo/review_neutral.geojson', 'petbarn-neutral', '#ffc226', '#000000');
 
      mapc.setPaintProperty('SA1', 'fill-color', '#42a7ff');
      mapc.setPaintProperty('SA1', 'fill-opacity', [
        'case',
        ['boolean', ['feature-state', 'active'], false],
        0.85,
        ['boolean', ['feature-state', 'hover'], false],
        0.5,
        0
      ]);

      const featuresInRegion = (sourceName: string, layerName: string[], region: mapboxgl.MapboxGeoJSONFeature[]) => {
        if (region.length === 0) return []
        const featuresInRange: mapboxgl.MapboxGeoJSONFeature[] = []
        const source = mapc.querySourceFeatures(sourceName)

        region.forEach((drawFeature) => {
          source.forEach((feature) => {
            if (inside(feature as GeoJSON.Feature<GeoJSON.Point>, drawFeature as GeoJSON.Feature<GeoJSON.Polygon>)) {
              featuresInRange.push(feature)
            }
          })
        })

        // console.log(sourceName, 'featuresInRange', featuresInRange)
        // // return uniq(featuresInRange, (f => f.properties.id))
        return featuresInRange
      }

      const calculateFeatures = async (e: {
        features: mapboxgl.MapboxGeoJSONFeature[];
        unionedRegion: any[];
      }) => {
        let totalArea = 0
        e.features.forEach((f) => {
          totalArea += area(f)
        })
        console.log('selectedFeatureChanged', e, 'totalArea:', totalArea)

        // const petstockStores = featuresInRegion('petstock-raw', ['petstock-unclustered-point', 'petstock-clusters', 'petstock-stores'], e.unionedRegion)
        const petbarnStores = featuresInRegion('petbarn-positive-raw', ['petbarn-positive-clusters', 'petbarn-positive-unclustered-point', 'petbarn-positive-stores'], e.unionedRegion)
        const petbarnNegativeStores = featuresInRegion('petbarn-negative-raw', ['petbarn-negative-clusters', 'petbarn-negative-unclustered-point', 'petbarn-negative-stores'], e.unionedRegion)
        const petbarnMixedStores = featuresInRegion('petbarn-neutral-raw', ['petbarn-neutral-clusters', 'petbarn-neutral-unclustered-point', 'petbarn-neutral-stores'], e.unionedRegion)

        setSelection({
          area: totalArea,
          features: e.features,
          petbarnStores,
          negativeStores: petbarnNegativeStores,
          neutralStores: petbarnMixedStores
        })
      }

      // const toolSingle = new ToolSingle(mapc, 'SA1')
      // toolSingle.register()
      const toolDraw = new ToolDraw(mapc, 'SA1', {
        onSelectedFeaturesChange: (e) => {
          if (e.features.length === 0) {
            setSelection(undefined)
            return
          }
          calculateFeatures(e)
        }
      })
      toolDraw.register()
    });
    
  });

  return (
    <div>
      <div ref={mapContainer} className={styles.mapContainer} />
      {selection && (
        <Details {...selection}
          sa1Data={sa1Data}
        />
      )}
    </div>
  );
};
