import logo from './assets/logo-white.svg'
import { Map } from './components/Map';
import './App.css'

function App() {
  return (
    <div className="App">
      <Map />
      <img className="logo" src={logo} alt="A Howatson+Company product"  />
    </div>
  )
}

export default App
